<template>
  <div class="fissionBoxAll">
    <!--头部-->
    <template v-if="$route.name == 'marketingFission'">
      <div class="header">
        <div class="boxTest">
          <el-button
            type="primary"
            style="color：#fff"
            @click="editActivity"
            v-if="fissionPosterNew"
          >
            新建活动
          </el-button>
        </div>
        <div class="storeSelect">
          <el-select v-model="post.join_condition" placeholder="全部条件">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>

        <div class="storeSelect_2">
          <el-select v-model="post.type" placeholder="全部状态">
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>

        <div class="input">
          <el-input
            placeholder="输入活动名称搜索"
            v-model="post.search"
            class="input-with-select"
          >
            <!-- 搜索 -->
          </el-input>
        </div>
      </div>

      <!-- <div
        class="flex-center"
        style="margin-bottom: 20px; margin-left: 20px"
        v-if="fissionPosterExtension"
      >
        <el-switch
          style="width: 33px"
          v-model="isExtension"
          active-color="#1B9D97"
          @change="extensionChange"
        ></el-switch>
        <div class="switch_font">
          推广中心已{{ isExtension == 1 ? '开启' : '关闭' }}
        </div>
        <el-popover placement="right" width="420" trigger="hover">
          <div class="extensionHoverBox">
            <p>
              开启后，在微信H5网校底部标签栏会展示【推广中心】模块，展示全部参与裂变活动的课程，方便学员进行推广；关闭后，学员只可在个人中心>>推广收益中查看
            </p>
            <img src="../../assets/img/1.3.9.6/img_syt@2x.png" alt="" />
          </div>
          <el-button
            icon="el-icon-question"
            type="text"
            slot="reference"
            circle
          ></el-button>
        </el-popover>
      </div> -->

      <div class="contentVideo">
        <div class="rightMenu">
          <!-- 右侧内容 列表- 分页 -->
          <div class="rightCon">
            <pagination2
              :option="post"
              url="/Fission/fissionList"
              ref="childFission"
              @complete="complete"
            >
              <template v-slot:default="{ tableData }">
                <el-table
                  :data="tableData"
                  @sort-change="sortChange"
                  max-height:200
                  :header-cell-style="{
                    background: 'rgba(245,245,245,1)',
                    color: '#333333',
                  }"
                >
                  <el-table-column prop="name" label="名称" min-width="120">
                    <template slot-scope="scope">
                      <div class="titleActivitName">
                        <p :title="scope.row.name">{{ scope.row.name }}</p>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="Share"
                    label="分成比"
                    sortable="custom"
                  >
                    <template slot-scope="scope">
                      <p>{{ scope.row.commission_ratio }} %</p>
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="dealOrder"
                    label="成交订单"
                    sortable="custom"
                  >
                    <template slot-scope="scope">
                      <p>{{ scope.row.order_num }}</p>
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="profit"
                    label="累计收益"
                    sortable="custom"
                  >
                    <template slot-scope="scope">
                      <p>
                        {{ scope.row.courseprice }}
                      </p>
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="commission"
                    label="累计佣金"
                    sortable="custom"
                  >
                    <template slot-scope="scope">
                      <p>
                        {{ scope.row.brokerage_amount }}
                      </p>
                    </template>
                  </el-table-column>

                  <el-table-column prop="file_size" label="条件">
                    <template slot-scope="scope">
                      <p>
                        {{
                          scope.row.join_condition == 1 ? '无需报名' : '需报名'
                        }}
                      </p>
                    </template>
                  </el-table-column>

                  <el-table-column prop="proportion" label="关联课程">
                    <template slot-scope="scope">
                      <div
                        class="relationClass"
                        @click="
                          editActivity(
                            scope.row.fission_id,
                            scope.row.status,
                            1
                          )
                        "
                      >
                        <p>{{ scope.row.course_num }}</p>
                        <img
                          src="../../assets/img/1.3.9.6/icon_gd@2x.png"
                          alt=""
                        />
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column prop="proportion" label="活动时间">
                    <template slot-scope="scope">
                      <div class="titleActivit">
                        <p>
                          {{
                            scope.row.start_time
                              | formatTimeStamp('yyyy-MM-dd hh:mm')
                          }}
                        </p>
                        <p>
                          {{
                            scope.row.end_time
                              | formatTimeStamp('yyyy-MM-dd hh:mm')
                          }}
                        </p>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column prop="proportion" label="状态">
                    <template slot-scope="scope">
                      <div class="titleActivit">
                        <p>
                          {{
                            scope.row.status == 1
                              ? '未开始'
                              : scope.row.status == 2
                              ? '进行中'
                              : '已结束'
                          }}
                        </p>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column label="操作">
                    <template slot-scope="scope">
                      <div class="mouseRight">
                        <div class="btns">
                          <span
                            type="text"
                            class="text"
                            style="color: #0aa29b"
                            @click="cilckSee(scope.row.fission_id)"
                          >
                            查看
                          </span>
                          <template>
                            <span class="space"></span>
                            <el-dropdown>
                              <span class="text">更多</span>
                              <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-if="fissionPosterEdit">
                                  <p
                                    :class="{ disabled: scope.row.status == 3 }"
                                    @click="
                                      editActivity(
                                        scope.row.fission_id,
                                        scope.row.status
                                      )
                                    "
                                  >
                                    编辑
                                  </p>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                  <p
                                    :class="{
                                      disabled:
                                        scope.row.status == 1 ||
                                        scope.row.status == 3,
                                    }"
                                    type="text"
                                    @click="
                                      endActivity(
                                        scope.row.fission_id,
                                        scope.row.status
                                      )
                                    "
                                  >
                                    {{
                                      scope.row.status == 1
                                        ? '未开始'
                                        : scope.row.status == 2
                                        ? '结束活动'
                                        : '已结束'
                                    }}
                                  </p>
                                </el-dropdown-item>
                                <el-dropdown-item v-if="fissionPosterDel">
                                  <p
                                    @click="
                                      delActivity(
                                        scope.row.fission_id,
                                        scope.row.status
                                      )
                                    "
                                  >
                                    删除活动
                                  </p>
                                </el-dropdown-item>
                              </el-dropdown-menu>
                            </el-dropdown>
                          </template>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </pagination2>
            <!--分页-->
          </div>
        </div>
      </div>
    </template>
    <router-view />
  </div>
</template>
<script>
import bg from '@/mixin/background'
export default {
  name: 'fissionBoxAll',

  mixins: [bg],
  data() {
    return {
      options: [
        {
          value: '0',
          label: '全部条件',
        },
        {
          value: '1',
          label: '无需报名',
        },
        {
          value: '2',
          label: '需报名',
        },
      ],
      options2: [
        {
          value: '0',
          label: '全部状态',
        },
        {
          value: '1',
          label: '未开始',
        },
        {
          value: '2',
          label: '进行中',
        },
        {
          value: '3',
          label: '已结束',
        },
      ],
      tableData: [],
      post: {
        type: '0',
        join_condition: '0',
        search: '',
        sort: '',
        order: '',
      },
      //推广中心开关
      isExtension: true,
    }
  },

  computed: {
    //新建活动
    fissionPosterNew() {
      return this.$store.state.roots.includes(142)
    },
    //编辑活动
    fissionPosterEdit() {
      return this.$store.state.roots.includes(143)
    },
    //删除活动
    fissionPosterDel() {
      return this.$store.state.roots.includes(150)
    },
    //开启关闭推广中心
    // fissionPosterExtension() {
    //   return this.$store.state.roots.includes(144)
    // },
  },

  created() {
    this.getData()
  },

  methods: {
    complete(val) {
      // console.log(val)
      if (val.is_show_fission == 1) {
        this.isExtension = true
      } else {
        this.isExtension = false
      }
    },
    newActivityCilck() {
      this.$router.push({
        path: '/marketingFission/newActivity',
      })
    },
    extensionChange(val) {
      // console.log(val)
      // this.isExtension = val
      var status = 0
      if (val == true) {
        status = 1
      } else {
        status = 2
      }
      this.$http({
        url: '/Fission/isShowFission',
        data: {
          status: status,
        },
        callback: () => {
          this.$root.prompt({
            msg: '设置成功',
            type: 'success',
          })
          this.$refs.childFission.reset()
        },
        error: () => {
          this.$root.prompt('设置失败')
        },
      })
    },
    //点击查看
    cilckSee(id) {
      // console.log(id)
      this.$router.push({
        path: '/marketingFission/promotionRecord/' + id,
      })
    },

    editActivity(id, status, num) {
      // 无权限访问
      if (!this.fissionPosterEdit) return;
      if (status == 3) {
        if (num == 1) {
          this.$root.prompt('活动已结束，暂不支持查看关联的课程！')
          return
        }
        return
      }
      //点击新建活动,关联课程,编辑
      //检测id数据类型 新建跳转id传new
      // console.log(typeof id)
      if (typeof id == 'string') {
        this.$router.push({
          path: '/marketingFission/newActivity/' + id,
        })
      } else {
        this.$router.push({
          path: '/marketingFission/newActivity/' + 'new',
        })
      }
    },
    endActivity(id, status) {
      //点击结束活动
      if (status == 2) {
        this.$confirm(
          '结束后，当前学员已生成裂变海报自动失效，已裂变产生的订单有效！结束后如需开启，可编辑活动时间重新开启，已关联的课程也将被释放。',
          '结束活动',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        ).then(() => {
          this.$http({
            url: '/Fission/toggleFissionStatus',
            data: {
              fission_id: id,
            },
            callback: () => {
              this.$root.prompt({
                msg: '结束成功',
                type: 'success',
              })
              this.$refs.childFission.reset()
            },
            error: () => {
              this.$root.prompt('结束失败')
            },
          })
        })
      }
    },
    delActivity(id, status) {
      //删除活动
      if (status == 2) {
        this.$confirm(
          '活动进行中，无法删除！如需删除，请先结束该活动！',
          '温馨提示',
          {
            confirmButtonText: '我知道了',
            showCancelButton: false,
          }
        )
      } else {
        this.$confirm(
          '删除后，当前学员已生成裂变海报自动失效，已裂变产生的订单有效！无法恢复！',
          '删除活动',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        ).then(() => {
          this.$http({
            url: 'Fission/deleteFission',
            data: {
              fission_id: id,
            },
            callback: () => {
              this.$root.prompt({
                msg: '删除成功',
                type: 'success',
              })
              this.$refs.childFission.reset()
            },
            error: () => {
              this.$root.prompt('删除失败')
            },
          })
        })
      }
    },

    getData() {},
    // 排序
    sortChange(value) {
      // console.log(value.prop)
      var sort = 0
      var order = 0
      switch (value.order) {
        case null:
          break
        case 'descending':
          sort = 2
          break
        case 'ascending':
          sort = 1
          break
      }
      switch (value.prop) {
        case null:
          break
        case 'Share':
          order = 1
          break
        case 'dealOrder':
          order = 2
          break
        case 'profit':
          order = 3
          break
        case 'commission':
          order = 4
          break
      }
      this.post.order = order
      this.post.sort = sort
    },
  },
}
</script>
<style lang="scss" scoped>
p {
  color: #333;
}
.disabled {
  color: #c0c4cc;
  cursor: not-allowed !important;
  opacity: 0.6;
}
::v-deep.el-dropdown-menu {
  .el-button--text {
    color: none;
  }
  width: 136px;
  p {
    width: 115px;
  }
}

// .disabled {
//   pointer-events: none;
//   cursor: default;
//   opacity: 0.6;
// }

.el-dialog__body {
  .el-select {
    width: 100%;
  }
}

.contentVideo {
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;

  .rightMenu {
    flex: 1;
    height: 100%;
    overflow-x: hidden;
    padding: 0px 20px 0px 20px;
    .titleActivitName {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .relationClass {
      display: flex;
      justify-content: flex-start;
      cursor: pointer;

      p {
        height: 14px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #1b9d97;
        line-height: 14px;
      }

      img {
        width: 6px;
        height: 14px;
        margin-left: 10px;
      }
    }
  }
}

.fissionBoxAll {
  background: #fff;
  margin: 20px 20px;
  border-top: 1px solid #fff;
}

::v-deep .fissionBoxAll .el-table {
  padding: 0 20px;
}

.header {
  display: flex;
  margin-bottom: 20px;
  min-width: 900px;
  margin-top: 20px;
  padding: 0 20px 0 20px;

  .uploading {
    width: 100px;
    height: 36px;
    background-color: #0aa29b;
    border-radius: 4px;
    text-align: center;
    line-height: 36px;
    color: #fff;
    font-size: 14px;
    // margin-top: 20px;
    margin-left: 20px;
    margin-right: 30px;
    cursor: pointer;
  }

  .boxTest {
    flex: 1;

    // margin-top: 20px;
    .c1 {
      flex: 1;
      width: 100px;
      height: 40px;
      background: #0aa29b;
      border-radius: 4px;
      cursor: pointer;
      text-align: center;

      p {
        height: 14px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #ffffff;
        line-height: 40px;
      }
    }
  }

  .storeSelect {
    width: 160px;
    height: 36px;
    border-radius: 4px;
    margin-right: 20px;
  }

  .storeSelect_2 {
    width: 160px;
    height: 36px;
    border-radius: 4px;
  }

  .input {
    margin-left: 20px;
  }
}

.flex-center {
  .switch_font {
    height: 14px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #333333;
    line-height: 14px;
    margin: 0 0 0 10px;
  }

  .el-button--text {
    color: rgb(211, 211, 211);
  }

  .el-button {
    font-size: 16px;
    margin-top: 2px;
  }
}

.extensionHoverBox {
  p {
    width: 420px;
    height: 54px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #4a4a4a;
    line-height: 18px;
  }

  img {
    width: 297px;
    height: 416px;
    margin-left: 49px;
    margin-top: 10px;
  }
}
.mouseRight {
}
.btns {
  @extend %btns;
  position: relative;
}
// ::v-deep .el-button--text{
//     color: #606266;
// }
</style>
