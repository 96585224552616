var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fissionBoxAll"},[(_vm.$route.name == 'marketingFission')?[_c('div',{staticClass:"header"},[_c('div',{staticClass:"boxTest"},[(_vm.fissionPosterNew)?_c('el-button',{staticStyle:{},attrs:{"type":"primary"},on:{"click":_vm.editActivity}},[_vm._v(" 新建活动 ")]):_vm._e()],1),_c('div',{staticClass:"storeSelect"},[_c('el-select',{attrs:{"placeholder":"全部条件"},model:{value:(_vm.post.join_condition),callback:function ($$v) {_vm.$set(_vm.post, "join_condition", $$v)},expression:"post.join_condition"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('div',{staticClass:"storeSelect_2"},[_c('el-select',{attrs:{"placeholder":"全部状态"},model:{value:(_vm.post.type),callback:function ($$v) {_vm.$set(_vm.post, "type", $$v)},expression:"post.type"}},_vm._l((_vm.options2),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('div',{staticClass:"input"},[_c('el-input',{staticClass:"input-with-select",attrs:{"placeholder":"输入活动名称搜索"},model:{value:(_vm.post.search),callback:function ($$v) {_vm.$set(_vm.post, "search", $$v)},expression:"post.search"}})],1)]),_c('div',{staticClass:"contentVideo"},[_c('div',{staticClass:"rightMenu"},[_c('div',{staticClass:"rightCon"},[_c('pagination2',{ref:"childFission",attrs:{"option":_vm.post,"url":"/Fission/fissionList"},on:{"complete":_vm.complete},scopedSlots:_vm._u([{key:"default",fn:function({ tableData }){return [_c('el-table',{attrs:{"data":tableData,"max-height:200":"","header-cell-style":{
                  background: 'rgba(245,245,245,1)',
                  color: '#333333',
                }},on:{"sort-change":_vm.sortChange}},[_c('el-table-column',{attrs:{"prop":"name","label":"名称","min-width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"titleActivitName"},[_c('p',{attrs:{"title":scope.row.name}},[_vm._v(_vm._s(scope.row.name))])])]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"Share","label":"分成比","sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(_vm._s(scope.row.commission_ratio)+" %")])]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"dealOrder","label":"成交订单","sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(_vm._s(scope.row.order_num))])]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"profit","label":"累计收益","sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(" "+_vm._s(scope.row.courseprice)+" ")])]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"commission","label":"累计佣金","sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(" "+_vm._s(scope.row.brokerage_amount)+" ")])]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"file_size","label":"条件"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(" "+_vm._s(scope.row.join_condition == 1 ? '无需报名' : '需报名')+" ")])]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"proportion","label":"关联课程"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"relationClass",on:{"click":function($event){return _vm.editActivity(
                          scope.row.fission_id,
                          scope.row.status,
                          1
                        )}}},[_c('p',[_vm._v(_vm._s(scope.row.course_num))]),_c('img',{attrs:{"src":require("../../assets/img/1.3.9.6/icon_gd@2x.png"),"alt":""}})])]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"proportion","label":"活动时间"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"titleActivit"},[_c('p',[_vm._v(" "+_vm._s(_vm._f("formatTimeStamp")(scope.row.start_time,'yyyy-MM-dd hh:mm'))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm._f("formatTimeStamp")(scope.row.end_time,'yyyy-MM-dd hh:mm'))+" ")])])]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"proportion","label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"titleActivit"},[_c('p',[_vm._v(" "+_vm._s(scope.row.status == 1 ? '未开始' : scope.row.status == 2 ? '进行中' : '已结束')+" ")])])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"mouseRight"},[_c('div',{staticClass:"btns"},[_c('span',{staticClass:"text",staticStyle:{"color":"#0aa29b"},attrs:{"type":"text"},on:{"click":function($event){return _vm.cilckSee(scope.row.fission_id)}}},[_vm._v(" 查看 ")]),[_c('span',{staticClass:"space"}),_c('el-dropdown',[_c('span',{staticClass:"text"},[_vm._v("更多")]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[(_vm.fissionPosterEdit)?_c('el-dropdown-item',[_c('p',{class:{ disabled: scope.row.status == 3 },on:{"click":function($event){return _vm.editActivity(
                                      scope.row.fission_id,
                                      scope.row.status
                                    )}}},[_vm._v(" 编辑 ")])]):_vm._e(),_c('el-dropdown-item',[_c('p',{class:{
                                    disabled:
                                      scope.row.status == 1 ||
                                      scope.row.status == 3,
                                  },attrs:{"type":"text"},on:{"click":function($event){return _vm.endActivity(
                                      scope.row.fission_id,
                                      scope.row.status
                                    )}}},[_vm._v(" "+_vm._s(scope.row.status == 1 ? '未开始' : scope.row.status == 2 ? '结束活动' : '已结束')+" ")])]),(_vm.fissionPosterDel)?_c('el-dropdown-item',[_c('p',{on:{"click":function($event){return _vm.delActivity(
                                      scope.row.fission_id,
                                      scope.row.status
                                    )}}},[_vm._v(" 删除活动 ")])]):_vm._e()],1)],1)]],2)])]}}],null,true)})],1)]}}],null,false,2878351101)})],1)])])]:_vm._e(),_c('router-view')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }